import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from "../components/Containers"
import useForms from "../hooks/useForms"

const Refer = () => {
  const { form } = useForms("referral")

  useEffect(() => {
    setTimeout(() => {
      const link = document.createElement("a")
      link.href = form.embedUrl
      link.click()
    }, 1000)
  }, [])

  return (
    <Layout navSpacer pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Referral Form"
        description="Doctors Referral Form"
      />

      <Section>
        <div
          className="mx-auto img-loading"
          style={{
            marginTop: 150,
            borderRadius: "10px",
            maxWidth: "300px",
            padding: "20px 100px",
            backgroundColor: "#e7f9ff",
            display: "flex",
            justifyContent: "center"
          }}>
          <p className="bold--600">Loading...</p>
        </div>
      </Section>
    </Layout>
  )
}

export default Refer
